body {
  margin: 0;

  /* changed for mdb-react-ui-kit */
  font-family: Roboto, Helvetica, Arial, sans-serif; 
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./images/bathroom-tile.jpg');
  background-repeat: repeat;

  /* https://mdbootstrap.com/snippets/standard/skumari/3292571#css-tab-view */
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn-info, .btn-info:hover, .btn-info:active, .btn-info:visited {
  background-color: brown !important;
  border: none !important;
  color: white !important;
  box-shadow: 0 0 0 0 #992222 !important;
}

.button-right {
  display: flex;
  justify-content: flex-end;
}

/* the rest of:
  https://mdbootstrap.com/snippets/standard/skumari/3292571#css-tab-view
*/
#story {
  background-color: #fff;
  margin: auto;
  border: 1px solid #dbdbdb;
  height: 110px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.story {
  border-radius: 50%;
}

.story-container ul {
  list-style-type: none;
  display: flex;
  overflow-y: auto;
  padding: 20px;
}

.story-container ul li {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.story-container ul li .story {
  padding: 2px;
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.logo-text {
  font-family: 'Permanent Marker', cursive;
}

.user-name {
  font-size: 12px;
}

.make-grayscale {
  filter: grayscale(100%);
}

.like-poop {
  background-color: rgba(255,255,255, 0.4) !important;
}

.pooplist-title {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.poop-header {
  color: #000;
  font-style: italic;
  font-size: 1.2rem;
}

.poop-footer {
  color: #000;
  font-style: italic;
  font-size: 0.9rem;
}

.poop-content {
  /* this allows it to be centered vertically with space at the top and bottom for header/footer */
  height:'calc(100% - 6rem)';
  color: #000;

}

.poop-text {
  width:80%;
  padding: .7rem;
  font-family: 'EB Garamond', serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  background-color: rgba(255,255,255, 0.4) !important;
}

.tint-white {
  background-color: rgba(255,255,255, 0.8) !important;
}
.tint-green {
  background-color: rgba(200,255,200, 0.8) !important;
}
.tint-brown {
  background-color: rgba(200,150,130, 0.8) !important;
}

.bg1 {
  background-image: url('./images/outhouse.jpg');
}

@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
